<template>
	<div class="page_box">

		<div class="top_box">
			<div class="top_box_title">
				福利信息
			</div>
			<div class="top_box_body">
				<span style="color: #2373C8;">{{fuli_info.title}}</span>
				<span>{{fuli_info.content}}</span>
				<span>工作满 {{fuli_info.day}} 天获取</span>
				<span>{{fuli_info.stopTime}}截止</span>
				<span style="color: #FF5E00;">
					<a :href="file_info.url" style="color:#FF5E00">
						{{file_info.name}}
					</a>
				</span>
				<!-- <span>2024-07-20</span> -->
				<span>达标人数：{{fuli_info.num}}人</span>
			</div>

			<div class="top_box_body">
				<span>福利说明：{{fuli_info.remark}}</span>
			</div>

		</div>

		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="do_export()">
						导出礼品数据
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" >
					<!-- 暂时无需 -->
					<el-table-column prop="real_name" label="姓名" width="">
						<template slot-scope="scope">
									{{ scope.row.name }}
									
							<!-- <el-popover trigger="hover" placement="top" :content="scope.row.real_name">
								<div slot="reference" class="name-wrapper">
									{{ scope.row.name }}
								</div>
							</el-popover> -->
						</template>
					</el-table-column>
					<el-table-column prop="depart_title" label="部门" width=""></el-table-column>
					<el-table-column prop="position" label="岗位" width=""></el-table-column>
					<el-table-column prop="cus_work_day" label="工作时长"></el-table-column>
					<el-table-column prop="cus_manzu" label="是否满足"></el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	export default {
		components: {
			page,
		},
		data() {
			return {
				gift_id: '',
				bm_id:'',
				send_id:'',
				fuli_info: {}, //福利信息
				file_info: {}, //附件信息

				usre_list: [],
				//

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
				 
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围

			};
		},
		computed: {},
		watch: {},
		created() {
			this.gift_id = this.$route.query.gift_id
			this.bm_id = this.$route.query.bm_id
			this.send_id = this.$route.query.send_id
			
			this.page_title = this.$route.meta.title
			this.query_detail()
			this.get_list()
			// this.setView()
		},
		mounted() {

		},

		methods: {
			setView() {
				this.query_user()
			},
			query_detail() {
				this.$api("giftDetail", {
					id: this.gift_id,

				}, "post").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.fuli_info = data;
						this.file_info = data.attact || {}
					}
				});
			},
			query_user() {
				this.$api("giftEmpList", {
					id: this.gift_id,
				}, "post").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						let list = res.data.list;
						list.forEach(v => {
							v.cus_work_day = v.workday + '天'
							v.cus_manzu = '是'
						})
						this.list = list;
						// this.fuli_info = data;
						// this.file_info = data.attact || {}
					}
				});
			},

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				this.loading = true
				this.$api("giftEmpList", {
					// mobile:this.mobile
					// ...this.forms,
					id: this.gift_id,
					// depart_id:this.bm_id,
					send_id:this.send_id,
					...this.pages,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						list.forEach(v => {
							v.cus_work_day = v.workday + '天'
							v.cus_manzu = '是'
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},
			do_export() {
				let token = localStorage.getItem('token')
				let url = this.API_LINK + `/api/exportEmpGift?id=${this.gift_id}&token=${token}`
				console.log('导出数据', url)
				window.open(url, '_blank');
			},
		},
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding: 18px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_box_body {
				margin-top: 28px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				span {
					min-width: 120px;
					// overflow: hidden;
					/* 确保超出容器的内容被裁剪 */
					// white-space: nowrap;
					/* 确保文本在一行内显示 */
					// text-overflow: ellipsis;
					/* 超出部分显示省略号 */
					font-weight: 400;
					font-size: 14px;
					color: #4A4A4A;
					display: block;
				}
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}

	.name-wrapper {
		color: #2373C8;
	}
</style>